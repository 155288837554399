body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  padding: 0px 0;
  text-align: center;
  /* align-content: 'center'; */
}

div.SolarSystem {
  /* align-content: center;
  height: 300px; */
  background-color: #000;
  width: 200px;
  height: 200px;
  border: 1px solid black;
  margin: 100px;
  padding: 0px;
  position: static; /* fixed or static */
  top: 100px;
  left: 100px;
}

/* container = document.getElementByID('SolarSystem') */


.PostMain {
    /* align-content: center; */
    /* box-sizing: border-box; */
    /* display: grid; */
    -webkit-hyphens: auto;
            hyphens: auto;
    line-height: 1.65;
    /* margin: 0 auto; */
    max-width: 100%;
    min-height: 100vh;
    padding: 0;
    text-align: center;
    align-content: center;
    font-family: PublicoText, Georgia, Cambria, Times New Roman;
}
.Post {
  align-content: center;
  /* box-sizing: border-box; */
  /* display: grid; */
  font-family: PublicoText, Georgia, Cambria, Times New Roman;
  /* hyphens: auto; */
  line-height: 1.65;
  margin: 0 auto;
  max-width: 80%;
  min-height: 100vh;
  padding: 0;
  text-align: left;
}

.SpotifyImage {
  max-width: 90%;
  height: auto;
}

.ImageDivSpotify {
  max-width: 100%;
  height: auto;
}

/* .LinksFullContainer {
  margin: 
} */
.LinksHeader {
  /* margin: 5% 10%; */
  text-align: center;
}

.Planet {
  align-content: center;
  display: grid;
  max-width: 680px;
  margin: 20px 0;
  padding: 0;
  text-align: left;
}

.PostPreviewHeader {
  align-content: center;
  display: grid;
  margin: 20px 0;
  padding: 0;
  text-align: center;
  max-width: 100%;
  color: #333;

}

.PostPreviewText {
  font-family: PublicoText, Georgia, Cambria, Times New Roman;
  color: #666;
}

.PostPreviewHeaderDiv {
  margin: 1% 20%;
}

@media (max-width: 900px) {
  .PostPreviewHeaderDiv {
    margin: 1% 15%;
  }
}

@media (max-width: 750px) {
  .LinksHeader {
    font-size: .75em;
  }
}

.social-container {
  padding: 0px 50px;
  margin-bottom: 10px;

}

.social-indiv {
  margin: 0 .75rem;
  transition: transform 250ms;
  display: inline-block;

}

.social:hover {
  transform: translateY(-2px);
}

a.instagram {
  color: black;
  height: 25px;
  width: 20px;
}

a.kaggle {
  color: #20beff;
  height: 25px;
  width: 20px;
}

a.github {
  color: #211F1F;
  height: 25px;
  width: 20px;
}

a.twitter {
  color: #4968ad;
  height: 25px;
  width: 20px;
}

a.linkedin {
  color: #b3bdc2;
  height: 25px;
  width: 20px;
}


h1 {
  font-size: 40px;
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
}

h1.Posttitle {
  color: #333;
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}


p {
  font-size: 16px;
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: PublicoText, Georgia, Cambria, Times New Roman;
  -webkit-hyphens: auto;
          hyphens: auto;
  margin: 0 auto;
  max-width: 50%;
  text-align: center;
  display: inline;

  
}

p.Back {
    font-size: 16px;
    align-content: center;
    box-sizing: border-box;
    display: grid;
    font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
      'Arial', sans-serif;
    -webkit-hyphens: auto;
            hyphens: auto;
    margin: 0 auto;
    max-width: 680px;
    text-align: center;

}


a:hover {
  border-bottom: 1px solid #0076ff;
  /* width: 10%; */
  margin: auto;
  text-decoration:none;
/* remove underline */
}
a.Links {
  color: #0076ff;
  cursor: pointer;
  transition: all 0.2s ease;
  width: auto;
  line-height: normal;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '`';
}

